
let teachers = [
	
	{
		name: 'erving hidalgo',
		material: 'director ejecutivo',
		img: require('../../../images/people/ceov3.png')
	},
	/*
	{
		name: 'fernando guerra',
		material: 'deirector de operaciones',
		img: require('../../../images/teachers/avatar.png')
	},*/
	{
		name: 'christian borquez',
		material: 'director comercial',
		img: require('../../../images/teachers/avatar.png'),
	},
	{
		name: 'jose luis barria',
		material: 'director de proyectos',
		img: require('../../../images/teachers/avatar.png')
	},
	
	{
		name: 'víctor sapiaín',
		material: 'director de tecnología',
		img: require('../../../images/people/ctov3.png')
	} 
]

export {
	teachers
}