
import React, {Component} from 'react'
import contact from '../../images/sections/contact.svg'
import './Contact.css'

class Contact extends Component
{
	render()
	{
		return (
			<div className = 'getintouch mb-5'>
			<div className = 'getintouch mb-5'>
				<div className = 'container'>
					<div className = 'title pt-3 pb-4 text-center'>						
						<h3 className = 'sub-title text-capitalize'>contactanos</h3>
					</div>
					<div className = 'row align-items-center'>
						<div className = 'c col-lg-6 col-12 mb-lg-0 mb-5'>
							<div className = 'left-side text-lg-start text-center'>
								<img className = 'img-fluid w-75' src = {contact} alt = 'contact' />
							</div>
						</div>
						<div className = 'c col-lg-6 col-12'>
							<div className = 'right-side'>
								<div className = 'row'>
									<div className = "form-group c col-md-12 col-12 mb-3">
								      	<label htmlFor = "last_name" className = 'mb-2 text-capitalize'>Nombre</label>
								      	<input type = "text" className = "form-control py-2 px-3" id = "first_name" placeholder="" required/>
								    </div>
								   
								    <div className = "form-group c col-12 mb-3">
								      	<label htmlFor = "e-mail" className = 'mb-2 text-capitalize'>Dirección de correo electrónico</label>
								      	<input type = "email" className = "form-control py-2 px-3" id = "e-mail" placeholder="" required/>
								    </div>
								    <div className = "form-group c col-12 mb-4">
								      	<label htmlFor = "textarea" className = 'mb-2 text-capitalize'>mensaje</label>
								      	<textarea className = "form-control py-2 px-3" id = "textarea" rows = '4'></textarea>
								    </div>
								    <button className = 'btn btn-outline-danger text-capitalize w-25 mx-auto'>enviar</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>
		)
	}
}

export default Contact