
import React, {Component} from 'react'
import './Solution.css'
import captura_1 from '../../images/others/captura_plataforma1.png'

class Solution extends Component
{
	render()
	{
		return (
			<div className = 'materials' id = 'materials'>
				<div className = 'container'>
					<div className = 'title py-4 text-left text-center'>
						<h4 className = 'sub-title text-capitalize'>Nuestros <span> servicios</span></h4>
					</div>
					<div className = 'row'>
						<h5 className='b-800'>Inteligencia de datos</h5>
						<p>
						Combinamos la inteligencia artificial y el aprendizaje automático, poniendo a disposición de las empresas herramientas y una infraestructura de datos para realizar un completo análisis de negocio que permitirá tomar decisiones basadas en los datos.
						</p>
						<h5 className='b-800'>Inteligencia Artificial</h5>
						<p>
						Ofrecemos servicios y soluciones de consultoría de inteligencia artificial que lo ayudarán a lograr sus objetivos comerciales más rápido.
						</p>
					
						<h5 className='b-800'>Inteligencia Artificial De Las Cosas (IoT + IA)</h5>
						<p>
						Creamos sistemas inteligentes y conectados que son capaces de tomar decisiones por sí mismos, evaluar los resultados de estas decisiones y mejorar con el tiempo.

						</p>
						<div className = 'title py-4 text-left text-center'>
							<h4 className = 'sub-title text-capitalize'>Nuestras <span> soluciones</span></h4>
						</div>	
						<div>
							<h4 className = 'text-capitalize b-800'> IALeap  <span className='font-sub'>Video Analítica</span>   </h4>
						</div>
						<div className = 'row align-items-center'>
							<div className = 'c col-lg-7 col-12'>
							<div className = 'left-side mb-lg-0 mb-5 text-lg-start text-justify'>
								Nuestras solucion unificada para analisis de videos inteligente utiliza inteligencia artificial para dar soluciones a problemas reales a cualquier industria. 							
								</div>
								<div className = 'left-side mb-lg-0 mb-5 text-lg-start text-justify'>
								Convierte tu cámara de video en una cámara inteligente con IALeap Video Analítica.
								</div>
								<br/>

								<h5 className='b-800'>Beneficios</h5>
								<div className = 'py-2 container'>
									<ul className='text-justify'>
									
									<li>
									<span className='b-800'>Mayor eficiencia y productividad.</span> Procesamiento de imágenes para el reconocimiento de patrones o características específicas que ayudan a automatizar varias tareas sin necesidad de intervención humana.
									</li>
									
									<li>
									<span className='b-800'>Alertas y notificaciones en tiempo real.</span> Reciba alertas y/o notificaciones en tiempo real  cuando existan incidencias o situaciones programadas.
									</li>
									<li>								
									<span className='b-800'>Indicadores para apoyar la toma de decisiones.</span> Permite guiar decisiones de negocios estratégicas a que se alineen con las metas, los objetivos y las iniciativas de una organización.
									</li>
									<li>	
									<span className='b-800'>Ahorro de costos.</span> Solución de costo relativamente bajo que aprovecha la infraestructura actual de vigilancias o la camara de un dispositivo móvil. Puede seleccionar una o varias áreas de un mismo dispositivo y aplicar algoritmos de IA.
									</li>
									
									</ul>
								</div>
							</div>
							<div className = 'c col-lg-5 col-12'>
								<div className = 'right-side text-lg-end text-center'>
									<img className = 'img-fluid mb-5' src = {captura_1} alt = 'IALeap Video Analítica' />
								</div>
							</div> 
						</div>
						<div >
							<h5 className='b-800'>Casos de uso</h5>	
							<div className = 'py-2 container'>
								<ul className='text-justify'>
								
								<li>
								<span > Contador De Personas, Vehículos Y Objetos.</span>
								</li>
								
								<li>
								<span > Detección De Personas.</span>
								</li>
								<li>								
								<span >Control De Aforo.</span>
								</li>
								<li>	
								<span >Reconocimiento Automático De Matrículas.</span> 
								</li>
								<li>	
								<span >Analitica Para Retail.</span> 
								</li>
								<li>	
								<span >Control De Seguridad.</span> 
								</li>
								<li>	
								<span >Reconocimiento Facial.</span> 
								</li>
								<li>	
								<span >Detección De Fatiga Y Somnolencia Del Conductor.</span> 
								</li>
								<li>	
								<span >Control De Calidad.</span> 
								</li>

								<li>	
								<span >Lector Multiple Para Códigos QR Y Códigos De Barra.</span> 
								</li>
								<li>	
								<span >Detección De Posturas.</span> 
								</li>
								</ul>
								
							</div>
							<h5 className='b-800'>Otras soluciones</h5> 
							<div className = 'py-2 container'>
								<ul className='text-justify'>
									<li>
										<span >Predicción de demandas.</span>
									</li>
									<li>
										<span >Predicción fuga de clientes</span>
									</li>
									<li>
										<span  >Análisis de sentimiento con analítica avanzada.</span>
									</li>
									<li className = 'mb-3'>
										<span  >Optimización Control de flotas con Inteligencia Artificial.</span>
									</li>									
								</ul>
							</div>						
						</div>						
					</div>
					<div className = 'col-md-4 col-12' >
						Escribenos a <span className='b-800 font-sub'> contact@ialeap.com </span>
					</div>
					<br></br>
				</div>
			</div>
		)
	}
}

export default Solution