
let accounts = [
	{
		icon: 'fab fa-facebook',
		link: 'https://www.facebook.com/'
	},
	{
		icon: 'fab fa-twitter',
		link: 'https://www.twitter.com/'
	},
	{
		icon: 'fab fa-youtube',
		link: 'https://www.youtube.com/'
	}
]

export {accounts}