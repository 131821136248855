
import React, {Component} from 'react'
import './Hero.css'
import {Link} from 'react-router-dom'
import about from '../../../images/sections/about.svg'
import logo from '../../../images/logo/ialeap_alt_sf.png'; // with import

class Hero extends Component
{
	render()
	{
		return (
			<div className = 'hero py-2'>
				<div className = 'container'>
					<div className = 'title pt-2 pb-5 text-center'>
						<h4 className = 'sub-title text-capitalize'>Sobre <span>nosotros</span></h4>
					</div>
					<div className = 'row align-items-center'>
						<div className = 'c col-lg-6 col-12'>
							<div className = 'left-side mb-lg-0 mb-5 text-lg-start'>
								<img  width="200" alt = 'leader' src = {logo} /> 
								<p className  = 'desc mb-lg-4 mb-5'>Somos un equipo de ingenieros y emprendedores experimentados apasionados por la  innovación, sustentabilidad y nuevos desafios. Estamos permanentemente desarrollando innovaciones de alto impacto, donde la tecnología principal es el uso de la I.A.</p>
							</div>
						</div>
						<div className = 'c col-lg-6 col-12'>
							<div className = 'right-side text-lg-end text-center'>
								<img className = 'w-75 img-fluid mb-5' src = {about} alt = 'about' />
							</div>
						</div>
					</div>
					<div className = 'buttons'>
						<Link className = 'btn btn-danger text-capitalize me-3 shadow' to = '/soluciones-y-servicios'>Nuestros servicios<i className="ms-2 fas fa-chevron-right"></i></Link>

						<Link className = 'btn btn-outline-danger text-capitalize shadow' to = '/contacto'>contactanos<i className="ms-2 fas fa-envelope"></i></Link>
					</div>
				</div>
				<div className = 'container'>
					<div className = 'title py-5 text-md-start text-justify'>
						<h4 className = 'sub-title text-capitalize'>Nuestra <span>misión</span></h4>
						<div className = 'c col-lg-12 col-12'>
							<div className = 'left-side mb-lg-0 mb-5 text-lg-start text-justify'>
								<p className  = 'desc mb-lg-4 mb-5'>Desarrollar soluciones innovadoras y prácticas, usando técnicas que se basan en IA. Soluciones que están orientadas para todo tipo de Industria o Entidades, cuyos problemas son complejos de tratar con el uso de técnicas simples.</p>
							</div>
						</div>
						<h4 className = 'sub-title text-capitalize'>Nuestra <span>visión</span></h4>
						<div className = 'c col-lg-12 col-12'>
							<div className = 'left-side mb-lg-0 mb-5 text-lg-start text-justify'>
								<p className  = 'desc mb-lg-4 mb-5'>Ser reconocidos como una empresa referente en el mercado tecnológico, sustentado en innovaciones propias, basadas en IA, con el objetivo de ayudar a distintas industrias a solucionar problemas concretos.</p>
							</div>
						</div>
					</div> 
				</div>
			</div>
		)
	}
}

export default Hero
