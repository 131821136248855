
//import React, {Component,useState } from 'react'
//import {Link, NavLink} from 'react-router-dom'
import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import './Navbar.css'
import {navbar_items} from './Data'
import logo from '../../images/logo/ialeap_original.jpg'; // with import


class Navbar extends Component
{
    state = {s:false,isNavExpanded:false,setIsNavExpanded:false}

    display_navbar_items_mob = _ =>
    {
        
        let items = navbar_items.map(item =>
        {
            return (
                <li className = "nav-item me-lg-3 my-lg-0 my-2" key = {Math.random()}>
                    <a href={`/${item.name === '' ? '':  item.link}`}>{item.name === '' ? 'inicio': item.name}  </a>
                </li>
            )
        })
        return items
    }

    display_navbar_items = _ =>
    {
        
        let items = navbar_items.map(item =>
        {
            return (
                <li className = "nav-item me-lg-3 my-lg-0 my-2" key = {Math.random()}>
                    <NavLink className = "nav-link text-capitalize position-relative hover" to = {`/${item.name === '' ? '':  item.link}`}><i className = {`${item.icon} me-2`}></i>{item.name === '' ? 'inicio': item.name}</NavLink>
                </li>
            )
        })
        return items
    }

    add_shadow = _ =>
    {
        (window.scrollY >= 80) ? this.setState({s:true}): this.setState({s:false}) 
    }

    componentDidMount()
    {
        window.addEventListener('scroll', event =>
        {
            this.add_shadow()
        })

        document.addEventListener('DOMContentLoaded', event =>
        {
            this.add_shadow()
        })
    }

   
	render()
	{
 
		return (
            
            <div>
                <nav  className = {`navbar navbar-expand-lg navbar-light text-dark fixed-top ${this.state.s ? "shadow-lg": "shadow"}`}>
                    <div className = 'container py-2'>
                        <a href="/" className="brand-name"><img  width="120" alt = 'leader' src = {logo} /></a>
                        
                        <button
                            className="hamburger"
                            onClick={() => {
                                this.setState({isNavExpanded:!this.state.isNavExpanded})
                            }}
                        >
                            {/* icon from heroicons.com */}
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="white"
                            >
                            <path
                                fillRule="evenodd"
                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                                clipRule="evenodd"
                            />
                            </svg>
                        </button>
                        <div
                            className={
                                this.state.isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
                            }
                  
                        >
 
                            <ul>
                                {this.display_navbar_items_mob()}
                            </ul>
                        </div>


                        <div className ="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">    
                            <ul className ="navbar-nav">
                                {this.display_navbar_items()}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
	   )
	}
}

export default Navbar