import React, {Component} from 'react'
import {Link} from 'react-router-dom';
import id_image from '../../../images/categories/id_img.jpg'
import imageMk from '../../../images/categories/mkv2.jpg';


import './Services.css'

class DI extends Component
{
	render()
	{
		return (
			<div className = 'materials py-4 mb-5' id = 'materials'>
				<div className = 'container'>
					<div className = 'title pt-2 pb-2  text-left'>
						<h4 className = 'sub-title text-capitalize'> Inteligencia<span>  de datos</span></h4>
					</div>
					<div className = 'row align-items-center'>
						<div className = 'c col-lg-7 col-12'>
							<div className = 'left-side mb-lg-0 mb-5 text-lg-start text-justify'>
								 Combinamos la inteligencia artificial y el aprendizaje automático, poniendo a disposición de las empresas herramientas y una infraestructura de datos para realizar un completo análisis de negocio que permitirá tomar decisiones basadas en los datos.
								Mediante la recopilación de datos actuales e históricos, ayudamos a las organizaciones a tomar mejores decisiones.
								</div>
								<br></br>			

								<h5 className='b-800'>Beneficios :</h5>
								<div className = 'py-2 container'>
									<ul className='text-justify'>
									
									<li>
										Ofrece una imagen más clara de la empresa gracias a la representación visual. Esto es verdaderamente útil para organizaciones de mayor tamaño, que están compuestas por varios departamentos y secciones interconectados, interpretadas como una unidad.
									</li>
									
									<li>
										Reduce la exposición al riesgo. Permite tomar decisiones informadas basadas en el conocimiento que se obtienes de los datos.
									</li>

									<li>								
										Permite a las empresas conocer dónde están consiguiendo un mayor retorno de su inversión y dónde están malgastando sus recursos y tomar las medidas necesarias al respecto.
									</li>
									</ul>
								</div>
						</div>
						<div className = 'c col-lg-5 col-12'>
							<div className = 'right-side text-lg-end text-center'>
								<img className = 'w-75 img-fluid mb-5' src = {id_image} alt = 'DI' />
							</div>
						</div> 
					</div>
				</div>
				<div className = 'container'>
					<div className = 'title pt-2 pb-2  text-left'>
						<h5 className='b-800'>Alguno de nuestros servicios:</h5>
					</div>
					<div className = 'row align-items-center'>
						<div className = 'c col-lg-7 col-12'>
							<div className = 'left-side mb-lg-0 mb-5 text-lg-start'>			
								<div className = 'py-2 container'>
									
								 <li>
								<span >
									Análisis y Visualización de Datos
 								</span>
								 </li>
								 <li>  
								<span >
									Análisis Predictivo para Marketing
								</span>
								</li>

							
								</div>
							</div>

						</div>
					</div>
					<br></br>
					<div className = 'col-md-3 col-12' >
						<Link className = 'btn btn-outline-danger text-capitalize shadow' to = '/contacto'>contactanos<i className="ms-2 fas fa-envelope"></i></Link>
					</div>
				</div>

				<div className="modal fade" id="mk" >
					<div className="modal-dialog modal-xl">
						<div className="modal-content">
						
							<div className="modal-body">
							<h5 className="modal-title">
								
								<h3 className = 'title text-capitalize'>Análisis Predictivo para Marketing</h3>
								<hr></hr>
							</h5>
						
							<div className = 'col-lg-12 text-justify'>

								Sabías que cuando interactuamos en redes sociales, los contenidos de plataformas como Instagram y Twitter, provienen de algoritmos de Inteligencia Artificial y que estos aprenden de nosotros.  

								De acuerdo a conclusiones de estudios en las universidades de Cambridge y Stanford, con 150 likes en Facebook, el algoritmo conocía mejor a la persona que un familar o su pareja y con 300 likes, incluso mejor que a sí mismo.   

								No te quedes atrás, se más competitivo y utiliza las técnicas que usan las grandes marcas y corporaciones para conocer a sus clientes, con los algoritmos más avanzados de inteligencia artificial, los cuales te permitirán : 
								
								<ul className='m-15'>
									<li>
									Adelantarte a las necesidades de tus clientes y ofrecerles contenido, producto o servicio que necesitan, incluso antes de que lo busquen.
									</li>
									<li>
									Ser más eficientes en el gasto de marketing con herramientas de automatización de gestión de audiencias y la distribución eficiente de la publicidad. Para desarrollar acciones de marketing y ventas de gran alcance, de forma totalmente personalizada y automatizada. 
									</li>
									<li>
									Segmentar y microsegmentar clientes, así como previsionar las ventas de estos								
									</li>
									<li>
									Mejorar sustancialmente las capacidades analíticas de tu empresa para disponer del máximo conocimiento de las motivaciones y objetivos de tus clientes y su comportamiento.  
									</li>
									<li>
									Análisis de los resultados de todas las actividades de marketing que realizamos.  								</li>
									<li>
									Con tecnología de Procesamiento del Lenguaje Natural (PLN) mejorar tus capacidades de procesar, aprender, organizar y publicar contenido. 								</li>
								</ul>
							</div>
							
							</div>
							<div className="modal-footer">
								<div className = 'buttons'>

									<Link className = 'btn btn-outline-danger text-capitalize shadow' to = '/contacto'>contactanos<i className="ms-2 fas fa-envelope"></i></Link>
								</div>
								<button type="button" className="btn btn-secondary" data-dismiss="modal">
									Cerrar
								</button>
							</div>
						</div>
					</div>
				</div>

			</div>
		)
	}
}

export default DI