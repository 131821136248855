import React, {Component} from 'react'
import {Link} from 'react-router-dom';
import ai_image from '../../../images/categories/iot_img.jpg'

import './Services.css'

class AI extends Component
{
	render()
	{
		return (
			<div className = 'materials py-4 mb-5' id = 'materials'>
				<div className = 'container'>
					<div className = 'title pt-2 pb-2  text-left'>
						<h4 className = 'sub-title text-capitalize'> Inteligencia Artificial De Las Cosas <span> (IoT + IA) </span></h4>
					</div>
					<div className = 'row align-items-center'>
						<div className = 'c col-lg-7 col-12'>
							<div className = 'left-side mb-lg-0 mb-5 text-lg-start text-justify'>
                            Combina tecnologías de inteligencia artificial (IA) y la infraestructura de Internet de las cosas (IoT).
                            <p>Mientras los dispositivos IoT recolectan información y actualizan las bases de datos, la Inteligencia Artificial analiza e interpreta los datos para encontrar patrones, realizar predicciones y tomar mejores decisiones al instante.</p>
							</div>
                            <br></br>
                            <h5 className='b-800'>Beneficios:</h5>
                            <div className = 'py-2 container'>
                            La perfecta combinación de estas tecnologías puede ayudar a las empresas a mejorar sus procesos operativos, recolectar y procesar información y, por ende, aumentar su valor de negocio.
                            <p>
                            Algunas de las proyecciones más prometedoras se encuentran en industrias inteligentes, medicina, ECommerce, smart homes.
                            </p>
								</div>
						</div>
                       <div className = 'c col-lg-5 col-12'>
							<div className = 'right-side text-lg-end text-center'>
								<img className = 'w-75 img-fluid mb-5' src = {ai_image} alt = 'AI' />
							</div>
						</div> 
					</div>
                    <br></br>
                    <div className = 'col-md-3 col-12' >
                        <Link className = 'btn btn-outline-danger text-capitalize shadow' to = '/contacto'>contactanos<i className="ms-2 fas fa-envelope"></i></Link>
                    </div>
				</div>
				
			</div>
		)
	}
}

export default AI