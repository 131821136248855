
import React, {Component} from 'react'
import Hero from './hero/Hero'
import Materials from './materials/Materials'
//import Stats from './stats/Stats'
import Emp from './emp/Emp'
import Success from './success/Success'
import Contact from '../contact/Contact'

class Landing extends Component
{
	render()
	{
		return (
			<>
				<Hero />
                <Materials />
                <Contact />

			</>
		)
	}
}

export default Landing