import React, {Component} from 'react'
import {Link} from 'react-router-dom';
import ai_image from '../../../images/categories/ia_img.jpg'

import './Services.css'

class AI extends Component
{
	render()
	{
		return (
			<div className = 'materials py-4 mb-5' id = 'materials'>
				<div className = 'container'>
					<div className = 'title pt-2 pb-2  text-left'>
						<h4 className = 'sub-title text-capitalize'> Inteligencia<span>  Artificial</span></h4>
					</div>
					<div className = 'row align-items-center'>
						<div className = 'c col-lg-7 col-12'>
							<div className = 'left-side mb-lg-0 mb-5 text-lg-start text-justify'>
                            Ayudamos a las empresas a crear soluciones de IA y ML. Nos ingeniamos para que pueda proyectar sus ideas en el futuro con tecnologías innovadoras de IA.
							</div>
                            <br></br>
                            <h5 className='b-800'>Beneficios:</h5>
                            <div className = 'py-2 container'>
									<ul className='text-justify'>
									
									<li>
                                    Reducción del error humano. 
									Los algoritmos de aprendizaje ayudan a determinar posibles escenarios de error y a realizar correcciones en tiempo real.
									</li>
									
									<li>
                                    Toma de decisiones inteligente. Las empresas utilizan la tecnología de inteligencia artificial para optimizar sus procesos diarios, analizar las próximas tendencias, pronosticar el crecimiento y predecir los resultados.
									</li>

									<li>								
									Mejoras en la Experiencia con cliente. Muchas empresas ahora usan chatbots en línea para simplificar el proceso de respuesta y resolución de problemas de los clientes.
									</li>
									</ul>
								</div>
						</div>
                       <div className = 'c col-lg-5 col-12'>
							<div className = 'right-side text-lg-end text-center'>
								<img className = 'w-75 img-fluid mb-5' src = {ai_image} alt = 'AI' />
							</div>
						</div> 
					</div>
				</div>
				<div className = 'container'>
					<div className = 'title pt-2 pb-2  text-left'>
						<h5 className='b-800'>Alguno de nuestros servicios:</h5>
					</div>
					<div className = 'row align-items-center'>
						<div className = 'c col-lg-7 col-12'>
							<div className = 'left-side mb-lg-0 mb-5 text-lg-start '>			
								<div className = 'py-2 container'>
									
								<li>
								<span >
                                    Vision artificial
								</span>
								</li> 
								<li>
								<span >
                                    Procesamiento de lenguaje natural (NLP)

								</span>
								</li> 
								<li> 
                                <span >
                                    Análisis y extracción de datos
								</span>
								</li> 
								<li> 
                                <span >
                                    Agentes inteligentes
								</span>
								</li> 
								<li> 
                                <span >
                                    Modelos de Machine Learning
								</span>
								</li> 
								</div>
							</div>

						</div>
					</div>
					<br></br>
					<div className = 'col-md-3 col-12' >
						<Link className = 'btn btn-outline-danger text-capitalize shadow' to = '/contacto'>contactanos<i className="ms-2 fas fa-envelope"></i></Link>
					</div>
				</div>
			</div>
		)
	}
}

export default AI