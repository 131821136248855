
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import './Hero.css'
import hero from '../../../images/sections/portada1.png'

class Hero extends Component
{
	render()
	{
		return (
			<div className = 'hero py-5 mb-5'>
				<div className = 'container'>
					<div className = 'row align-items-center'>
						<div className = 'c col-lg-6 col-12'>
							<div className = 'left-side mb-lg-0 mb-5 text-lg-start text-justify'>
								<h3 className = 'title text-capitalize'>El futuro <span>es hoy</span></h3>
								<h5 className = 'sub-title text-muted text-capitalize'>Genera Ventajas A Tu Negocio Con  Inteligencia Artificial </h5>
								<p className = 'desc pb-3 text-justify' >
									Optimiza tu negocio como las empresas más exitosas del mundo, mediante aplicaciones de Inteligencia Artificial para mejorar el servicio al cliente, automatización de cargas de trabajo, mejoras en eficiencias de producción, predicción de comportamientos, mejoras en la administración y análisis de datos. 								
								</p>
								<div className = 'buttons'>
									<Link className = 'btn btn-danger text-capitalize me-3 shadow' to = '/acerca-de-ialeap'>Conocenos<i className="ms-2 fas fa-chevron-right"></i></Link>

									<Link className = 'btn btn-outline-danger text-capitalize shadow' to = '/contacto'>contactanos<i className="ms-2 fas fa-envelope"></i></Link>
								</div>
							</div>
						</div>

						<div className = 'c col-lg-6 col-12'>
							<div className  = 'right-side text-lg-end text-center'>
								<img className = 'w-75 img-fluid' src = {hero} alt = 'hero' />
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Hero