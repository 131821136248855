
import React, {Component} from 'react'
import './Success.css'
import {section3} from './Data'
 
class Success extends Component
{
	display_success = _ =>
	{
		let items = section3.map((item, index) =>
		{
			return (
				<div className = 'c col-lg-3 col-md-6 col-12' key = {index}>
					<div className = 'card mx-auto shadow-lg mb-5' key = {index}>
						<img className = "img-fluid mb-3" src = {item.default} alt = {`blog number ${index}`} />
						<div className = 'card-body p-2'>
							<h5 className = 'card-img-top card-title mb-'>Caso éxito</h5>
							<p className = 'card-text mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...</p>
							<div className = '_footer d-flex flex-wrap justify-content-between align-items-center'>
								<button className = 'btn btn-danger text-capitalize mb-3 btn-sm'>leer más <i className = 'fas fa-chevron-right ms-2'></i></button>
							</div>
						</div>
					</div>
				</div>
			)
		})
		return items
	}
	render()
	{
		return (
			<div className = 'sections blogs _rs py-2 mb-5'>
				<div className = 'container'>
					<div className = 'container'>
						<div className = 'title py-5 text-md-start text-left'>
							<h4 className = 'sub-title text-capitalize'>casos de <span>éxito</span></h4>
						</div>
						<div className = '_s row justify-content-start'>
							{this.display_success()}
							 
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Success