
let categories = [
	
	
	
	{
		img: require('../../../images/logo/company/ixxosv3.png'),
		name: 'IXXOS'
		
	}, 
	/*
	{
		img: require('../../../images/logo/company/e2.jpg'),
		name: 'Empresa2'
		
	},
	{
		img: require('../../../images/logo/company/e3.jpg'),
		name: 'Empresa3'
		
	},
	{
		img: require('../../../images/logo/company/e4.jpg'),
		name: 'Empresa4'
		
	}
	*/
]

export {categories}