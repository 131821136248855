
import React, {Component} from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Navbar from './components/navbar/Navbar'
import Footer from './components/footer/Footer'
import Landing from './components/landing/Landing'
import About from './components/about/About'
//import Blogs from './components/blogs/Blogs'
import Sb from './components/scrollbtn/Sb'
//import Courses from './components/courses/Courses'
import Contact from './components/contact/Contact'
import DI from './components/landing/categories/DI'
import AI from './components/landing/categories/AI'
import AIOT from './components/landing/categories/AIOT'
import Solution from './components/solution/Solution'

import Notfound from './components/notfound/Notfound'


class App extends Component
{
    render()
    {
  		return (
          <BrowserRouter>
            <>
              <Sb />
              <div className = '_navbar'>
                  <Navbar />
              </div>

              <div className = '_body'>
                  <Routes>
                      <Route exact path = '/' element = {<Landing />} />
                      <Route path = '/acerca-de-ialeap' element = {<About />} />
                      <Route path = '/contacto' element = {<Contact />} />
                      <Route path = '/servicios/inteligencia-de-datos' element = {<DI />} />
                      <Route path = '/servicios/inteligencia-artificial' element = {<AI />} />
                      <Route path = '/servicios/aiot' element = {<AIOT />} />
                      <Route path = '/soluciones-y-servicios' element = {<Solution />} />
                      
                      <Route path = '*' element = {<Notfound />} />
                  </Routes>
              </div>

              <div className = '_footer'>
                <Footer />
              </div>
            
            </>
          </BrowserRouter>
        )
    }
}
export default App
