
let navbar_items = [
	{
		name: '',
		icon: 'fas fa-home',
	},
	{
		name: 'soluciones y servicios',
		icon: 'fas fa-chart-network',
		link: 'soluciones-y-servicios',
	},
	/*
	{
		name: 'casos de éxito',
		icon: 'fas fa-check',
		link: 'success',
	},
	*/
	{
		name: 'nosotros',
		link: 'acerca-de-ialeap',
		icon: 'fa fa-users',
	},
	
	
	/*
	{
		name: 'blogs',
		link:'blogs',
		icon: 'fas fa-blog',
	},*/
	{
		name: 'contacto',
		link:'contacto',
		icon: 'fa fa-envelope',
	}
]

export {
	navbar_items
}