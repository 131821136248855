let categories = [
	{
		id:1,
		img: require('../../../images/categories/id_img.jpg'),
		name: 'Inteligencia de Datos'
		
	},
	{
		id:2,
		img: require('../../../images/categories/ia_img.jpg'),
		name: 'inteligencia artificial'
	},
	/*
	{
		id:3,
		img: require('../../../images/categories/cb_img.jpg'),
		name: 'ciberseguridad'
	} ,
	*/
	,
	{
		id:3,
		img: require('../../../images/categories/iot_img.jpg'),
		name: 'Inteligencia artificial de las cosas (IoT + IA)'
	},
	/*
	{
		id:4,
		img: require('../../../images/categories/sd_img.jpg'),
		name: 'Soluciones digitales'
	}
	*/
]
export {categories}