
import React, {Component} from 'react'
import './Materials.css'
import {categories} from './Data'
import {Link} from 'react-router-dom';
import captura_1 from '../../../images/others/captura_plataforma1.png'

class Materials extends Component
{
	display_categories = _ =>
	{
		let items = categories.map((item, index) =>
		{
			return (
			<div className = 'c col-lg-4 col-md-6 col-12 mb-3' key = {index}>
				<div className = 'card min-height-card-r4'>
					<img alt="" className = 'card-img-top position-relative' src = {item.img.default} />

					<div className = 'card-body py-3 px-4'>
						<div className = 'top mb-2 d-flex flex-wrap justify-content-between'>
							<h6 className = 'teacher text-capitalize mb-2'>{item.name}</h6>
							 
						</div>
						<div className = 'desc mb-4 ps-3'>  
							{(() => {
								if (item.id===1) {
								return (									
									<div className='text-justify'>
										Combinamos la inteligencia artificial y el aprendizaje automático, poniendo a disposición de las empresas herramientas y una infraestructura de datos para realizar un completo análisis de negocio que permitirá tomar decisiones basadas en los datos.
										<div className = 'bottom d-flex flex-wrap justify-content-between align-items-center'>
											<Link to="/servicios/inteligencia-de-datos">
												<button className = 'btn btn-danger text-capitalize btn-sm mb-3'   >leer más</button>
											</Link>
										</div>	
									</div>
								)
								} else if (item.id===2) {
								return (
									<div className='text-justify'>
										Ofrecemos servicios y soluciones de consultoría de inteligencia artificial que lo ayudarán a lograr sus objetivos comerciales más rápido.
										<div className = 'bottom d-flex flex-wrap justify-content-between align-items-center'>
											<Link to="/servicios/inteligencia-artificial">
												<button className = 'btn btn-danger text-capitalize btn-sm mb-3'   >leer más</button>
											</Link>
										</div>	
									</div>
								)
								}if (item.id===3) {
									return (
										
										<div className='text-justify'>
											Creamos sistemas inteligentes y conectados que son capaces de tomar decisiones por sí mismos, evaluar los resultados de estas decisiones y mejorar con el tiempo.
											<div className = 'bottom d-flex flex-wrap justify-content-between align-items-center'>
												<Link to="/servicios/aiot">
													<button className = 'btn btn-danger text-capitalize btn-sm mb-3'   >leer más</button>
												</Link>
											</div>	
										</div>
									)
								} 
								else {
								return (
									<div>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
										<div className = 'bottom d-flex flex-wrap justify-content-between align-items-center'>
											<Link to="/">
												<button className = 'btn btn-danger text-capitalize btn-sm mb-3'   >leer más</button>
											</Link>
										</div>	
									</div>
								)
								}
							})()}
						</div>
						 
					</div>
				</div>
			</div>
			
			)
		})
		return items
	}

	display_sol = _ =>
	{
		return (
			<div>
				<div className = 'container'>
					 	<div >
							<h4 className = 'text-capitalize b-800'> IALeap  <span className='font-sub'>Video Analítica</span>   </h4>
						</div>
						<div className = 'row align-items-center'>
							<div className = 'c col-lg-7 col-12'>
							<div className = 'left-side mb-lg-0 mb-5 text-lg-start text-justify'>
								Nuestras solucion unificada para analisis de videos inteligente utiliza inteligencia artificial para dar soluciones a problemas reales a cualquier industria. 							
								</div>
								<div className = 'left-side mb-lg-0 mb-5 text-lg-start text-justify'>
								Convierte tu cámara de video en una cámara inteligente con IALeap Video Analítica.
								</div>
								<br/>

								<h5 className='b-800'>Beneficios</h5>
								<div className = 'py-2 container'>
									<ul className='text-justify'>
									
									<li>
									<span className='b-800'>Mayor eficiencia y productividad.</span> Procesamiento de imágenes para el reconocimiento de patrones o características específicas que ayudan a automatizar varias tareas sin necesidad de intervención humana.
									</li>
									
									<li>
									<span className='b-800'>Alertas y notificaciones en tiempo real.</span> Reciba alertas y/o notificaciones en tiempo real  cuando existan incidencias o situaciones programadas.
									</li>
									<li>								
									<span className='b-800'>Indicadores para apoyar la toma de decisiones.</span> Permite guiar decisiones de negocios estratégicas a que se alineen con las metas, los objetivos y las iniciativas de una organización.
									</li>
									<li>	
									<span className='b-800'>Ahorro de costos.</span> Solución de costo relativamente bajo que aprovecha la infraestructura actual de vigilancias o la camara de un dispositivo móvil. Puede seleccionar una o varias áreas de un mismo dispositivo y aplicar algoritmos de IA.
									</li>
									
									</ul>
								</div>
							</div>
							<div className = 'c col-lg-5 col-12'>
								<div className = 'right-side text-lg-end text-center'>
									<img className = 'img-fluid mb-5' src = {captura_1} alt = 'IALeap Video Analítica' />
								</div>
							</div> 
						</div>
				</div>

				<div className = 'container'>
					<h5 className='b-800'>Casos de uso</h5> 

					<div className = 'row'>
						<div className = 'c col-lg-3 col-md-6 col-12 mb-3' >
							<div className = 'card min-height-card'>
								<div className = 'card-body'>
									<div className = 'top mb-2 d-flex flex-wrap justify-content-between'>
										<h6 className = 'text-capitalize b-800'><i className="fa fa-bookmark font-sub" aria-hidden="true"></i> Contador de personas, vehículos y objetos</h6>
									</div>
									<hr className='hr-bold'></hr>
									<div className = 'desc mb-4 ps-3 justify-content-between '>  
										Permite medir el trafico peatonal y/o vehicular. También permite determinar en qué áreas se quiere contar personas para evaluar qué productos están recibiendo la mayor atención.
									</div>

								</div>
							</div>
						</div>
						<div className = 'c col-lg-3 col-md-6 col-12 mb-3' >
							<div className = 'card  min-height-card'>
								<div className = 'card-body'>
									<div className = 'top mb-2 d-flex flex-wrap justify-content-between'>
										<h6 className = 'text-capitalize b-800'><i className="fa fa-bookmark font-sub" aria-hidden="true"></i> Detección de personas</h6>
									</div>
									<hr className='hr-bold'></hr>
									<div className = 'desc mb-4 ps-3 text-justify'>  
										Alerta a los interesados cuando se está en presencia de una persona en un área determinada.
									</div>

								</div>
							</div>
						</div>
						<div className = 'c col-lg-3 col-md-6 col-12 mb-3' >
							<div className = 'card min-height-card'>
								<div className = 'card-body'>
									<div className = 'top mb-2 d-flex flex-wrap justify-content-between'>
										<h6 className = 'text-capitalize b-800'><i className="fa fa-bookmark font-sub" aria-hidden="true"></i> Control de aforo</h6>										
									</div>
									<hr className='hr-bold'></hr>
									<div className = 'desc mb-4 ps-3 text-justify'>  
									Detecta el nivel de ocupación en todo tipo de espacios abiertos como: centro comerciales, playas, parques, conciertos, etc.
									</div>
								</div>
							</div>
						</div>
						<div className = 'c col-lg-3 col-md-6 col-12 mb-3' >
							<div className = 'card  min-height-card'>
								<div className = 'card-body'>
									<div className = 'top mb-2 d-flex flex-wrap justify-content-between'>
										<h6 className = 'text-capitalize b-800'><i className="fa fa-bookmark font-sub" aria-hidden="true"></i> Reconocimiento automático de matrículas</h6>
									</div>
									<hr className='hr-bold'></hr>
									<div className = 'desc mb-4 ps-3 text-justify'>  
										Seleccionando el área de interés nuestra solución es capaz de leer multiples placas de patentes de vehiculos. 
									</div>
								</div>
							</div>
						</div>
						<div className = 'c col-lg-3 col-md-6 col-12 mb-3' >
							<div className = 'card  min-height-card-r2'>
								<div className = 'card-body'>
									<div className = 'top mb-2 d-flex flex-wrap justify-content-between'>
										<h6 className = 'text-capitalize b-800'><i className="fa fa-bookmark font-sub" aria-hidden="true"></i> Analitica para retail</h6>										
									</div>
									<hr className='hr-bold'></hr>
									<div className = 'desc mb-4 ps-3 text-justify'>  
										Puede determinar datos demográficos, tiempo de permanencia, tiempo de espera de los visitantes únicos en un espacio determinado.
									</div>

								</div>
							</div>
						</div>
						<div className = 'c col-lg-3 col-md-6 col-12 mb-3' >
							<div className = 'card  min-height-card-r2'>
								<div className = 'card-body'>
									<div className = 'top mb-2 d-flex flex-wrap justify-content-between'>
										<h6 className = 'text-capitalize b-800'><i className="fa fa-bookmark font-sub" aria-hidden="true"></i> Control de seguridad</h6>
										
									</div>
									<hr className='hr-bold'></hr>
									<div className = 'desc mb-4 ps-3 text-justify'>  
									Ayuda a rastrear y predecir accidentes en el lugar de trabajo y así evitar que sucedan. 
									</div>

								</div>
							</div>
						</div>
						<div className = 'c col-lg-3 col-md-6 col-12 mb-3' >
							<div className = 'card  min-height-card-r2'>
								<div className = 'card-body'>
									<div className = 'top mb-2 d-flex flex-wrap justify-content-between'>
										<h6 className = 'text-capitalize b-800'><i className="fa fa-bookmark font-sub" aria-hidden="true"></i> Reconocimiento facial</h6>
										
									</div>
									<hr className='hr-bold'></hr>
									<div className = 'desc mb-4 ps-3 text-justify'>  
									Identifica o confirma la identidad de una persona a partir de la captura de un rostro.

									</div>

								</div>
							</div>
						</div>
						<div className = 'c col-lg-3 col-md-6 col-12 mb-3' >
							<div className = 'card  min-height-card-r3'>
								<div className = 'card-body'>
									<div className = 'top mb-2 d-flex flex-wrap justify-content-between'>
										<h6 className = 'text-capitalize b-800'><i className="fa fa-bookmark font-sub" aria-hidden="true"></i> Detección de fatiga y somnolencia del conductor</h6>
										
									</div>
									<hr className='hr-bold'></hr>
									<div className = 'desc mb-4 ps-3 text-justify'>  
									Detecta los signos de fatiga y la tendencia a quedarse dormidos de la persona durante la conducción. Reciba alertas cuando se identifique que los ojos del conductor se cierren durante un período de tiempo prolongado.
									</div>

								</div>
							</div>
						</div>
						<div className = 'c col-lg-3 col-md-6 col-12 mb-3' >
							<div className = 'card  min-height-card-r3'>
								<div className = 'card-body'>
									<div className = 'top mb-2 d-flex flex-wrap justify-content-between'>
										<h6 className = 'text-capitalize b-800'><i className="fa fa-bookmark font-sub" aria-hidden="true"></i> Control de calidad</h6>
										
									</div>
									<hr className='hr-bold'></hr>
									<div className = 'desc mb-4 ps-3 text-justify'>  
										Inspecciona productos en busca de defectos, mide dimensiones e identifica patrones en los datos de producción. Esto puede ayudar a los fabricantes a reducir los desechos y mejorar la calidad de sus productos.
									</div>

								</div>
							</div>
						</div>
						<div className = 'c col-lg-3 col-md-6 col-12 mb-3' >
							<div className = 'card  min-height-card-r3'>
								<div className = 'card-body'>
									<div className = 'top mb-2 d-flex flex-wrap justify-content-between'>
										<h6 className = 'text-capitalize b-800'><i className="fa fa-bookmark font-sub" aria-hidden="true"></i> Lector multiple para códigos QR y códigos de barra </h6>
										
									</div>
									<hr className='hr-bold'></hr>
									<div className = 'desc mb-4 ps-3 text-justify'>  
										Con sólo seleccionar el área de interés nuestra solución es capaz de leer en forma multiple códigos de barra y/o codigos QR.
									</div>

								</div>
							</div>
						</div>
						<div className = 'c col-lg-3 col-md-6 col-12 mb-3' >
							<div className = 'card  min-height-card-r3'>
								<div className = 'card-body'>
									<div className = 'top mb-2 d-flex flex-wrap justify-content-between'>
										<h6 className = 'text-capitalize b-800'><i className="fa fa-bookmark font-sub" aria-hidden="true"></i> Detección de posturas</h6>
										
									</div>
									<hr className='hr-bold'></hr>
									<div className = 'desc mb-4 ps-3 text-justify'>  
									Estimación de la postura de individuos con distintos campos de aplicación como el estudio de la ergonomía en puestos de trabajo.
									</div>

								</div>
							</div>
						</div>
						<h5 className='b-800'>Otras soluciones</h5> 
						<div className = 'py-2 container'>
							<ul className='text-justify'>
								<li>
									<span >Predicción de demandas.</span>
								</li>
								<li>
									<span >Predicción fuga de clientes</span>
								</li>
								<li>
									<span  >Análisis de sentimiento con analítica avanzada.</span>
								</li>
								<li className = 'mb-3'>
									<span  >Optimización Control de flotas con Inteligencia Artificial.</span>
								</li>									
							</ul>
						</div>						
						
 						<h5 className='b-800'>¿ Necesitas una solución personalizada ? ¿ Tienes alguna duda ?</h5> 
						 
						<div className = 'col-md-4 col-12' >
							Escribenos a <span className='b-800 font-sub'> contact@ialeap.com </span>
						</div>
					</div> 
				</div>
			</div>
		)
	}

	render()
	{
		return (
			<div className = 'materials  my-5' id = 'materials'>
				<div className = 'container'>
					<div className = 'title py-3 text-left'>
						<h4 className = 'sub-title text-capitalize'>Nuestros    <span> Servicios</span></h4>
					</div>
					<div className = 'row justify-content-lg-between justify-content-center'>
						{this.display_categories()}
					</div>
					<div className = 'title py-3 text-left'>
						<h3 className = 'sub-title text-capitalize'>Nuestras    <span> Soluciones</span></h3>
					</div>
					<div className = 'row justify-content-lg-between justify-content-center'>
						{this.display_sol()}
					</div>
				</div>
			</div>
		)
	}
}

export default Materials